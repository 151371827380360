<template>
  <div>
    <template v-if="d_dataList.length > 0" v-for="(row, row_index) in d_dataList">
      <b-row style="margin: 30px;">
        <b-col sm="12" lg="1">
          {{ row.id }}
        </b-col>
        <b-col sm="12" lg="1">
          {{ row.client_id }}
        </b-col>
        <b-col sm="12" lg="1">
          {{ row.created_at }}
        </b-col>
        <b-col sm="12" lg="1">
          {{ row.parent_c }}
        </b-col>
        <b-col sm="12" lg="7">
          {{ row.data }}
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import { ServiceFunctions } from '@/wam/modules/service';
import {
  default as PageInfo
} from '@/components/widgets/PageInfo';
import { mapGetters } from 'vuex';
export default {
  name: 'db',
  computed: mapGetters({
    StoreLang: 'StoreLang',
  }),
  components: {
    PageInfo,
  },
  props: {},
  data () {
    return {
      d_dataList: [],
      d_pageInfoData: '',
      d_version: version,
    };
  },
  created: function () {
    this.f_getData();
  },
  beforeMount () {},
  mounted: function () {},
  methods: {
    f_getData: function () {
      this.d_dataList = [];
      // console.log('this.$route : ', this.$route);
      let layer_code = this.$route.meta.data_unit;
      let query = 'layer_code=' + layer_code;
      let data = {};
      ServiceFunctions.data_get(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            this.d_dataList = resp.data.list;
          } else {
            alert(resp.data.status_code + ' = ' + resp.data.status_message);
          }
        });
    }
  },
  watch: {
    '$route.name': function () {
      this.f_getData();
    }
  }
}

</script>

